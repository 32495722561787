import { BrowserModule, Title } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { RouterModule } from '@angular/router';

import { AppComponent } from './app.component';
import { NavMenuComponent } from './nav-menu/nav-menu.component';
import { HomeComponent } from './home/home.component';
import { LandingPageComponent } from './landing-page/landing-page.component';
import { AbortPageComponent } from './abort-page/abort-page.component';
import { DpaComponent } from './dpa/dpa.component';
import { LandingPageComponentDpa } from './landing-page-dpa/landing-page-dpa.component';
import { AbortPageComponentDpa } from './abort-page-dpa/abort-page-dpa.component';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {
  MatCheckboxModule,
  MatButtonModule,
  MatFormFieldModule,
  MatInputModule,
  MatProgressSpinnerModule,
  MatRadioModule,
  MatSelectModule,
  MatTooltipModule
} from '@angular/material';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';



@NgModule({
  declarations: [
    AppComponent,
    NavMenuComponent,
    HomeComponent,
    AbortPageComponent,
    LandingPageComponent,
    DpaComponent,
    LandingPageComponentDpa,
    AbortPageComponentDpa,
  ],
  imports: [
    BrowserModule.withServerTransition({ appId: 'ng-cli-universal' }),
    HttpClientModule,
    FormsModule,
    RouterModule.forRoot([
      { path: '', component: HomeComponent, pathMatch: 'full' },
      { path: 'abort', component: AbortPageComponent },
      { path: 'success', component: LandingPageComponent },
      { path: 'dpa', component: DpaComponent },
      { path: 'success-dpa', component: LandingPageComponentDpa },
      { path: 'abort-dpa', component: AbortPageComponentDpa },

    ]),
    MatCheckboxModule,
    MatButtonModule,
    MatFormFieldModule,
    MatInputModule,
    MatTooltipModule,
    MatSelectModule,
    MatProgressSpinnerModule,
    MatRadioModule,
    FormsModule,
    MatButtonModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
  ],
  providers: [Title],
  bootstrap: [AppComponent]
})
export class AppModule { }
