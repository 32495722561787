import { Component, OnInit } from '@angular/core';
import {Title} from "@angular/platform-browser";

@Component({
  selector: 'app-landing-page-dpa',
  templateUrl: './landing-page-dpa.component.html',
  styleUrls: ['./landing-page-dpa.component.css']
})
export class LandingPageComponentDpa implements OnInit {

  constructor(private titleService: Title) {
    this.titleService.setTitle("Signicat DPA");
  }

  ngOnInit() {
  }

}
