export class TinglysningsData {
    name: string;
    epost: string;
    mobil: string;
    firma: string;
    orgNr: string;
    adresse: string;
    postnr: string;
    poststed: string;
    bank: Bank;
    subIssuer: Bank;
    productionUrl: string;
    signature1: Signature;
    signature2: Signature; 
    dpaName: string;
    dpaContact: string;
    dpaNumber: string;
    dpaNextOfKin: string;
    dpaChildren: string;
    dpaNin: string;
    dpaEngagement: string;
    dpaInsurance: string;
    dpaFinancial: string;
    dpaMedical: string;
    dpaGenetic: string;
    dpaBiometric: string;
    dpaSexual: string;
    dpaCriminal: string;
    dpaEthnic: string;
    dpaPolitical: string;
    dpaUnion: string;
    dpaComment: string;
  }

  export class Bank {
    name: string;
    entityId: string; 
  }
  export class Signature {
    name: string; 
    email: string;
    mobile: string; 
  }
  