import { Component, OnInit } from '@angular/core';
import {FormControl, FormGroup} from '@angular/forms';
import {DocumentService} from '../shared/document.service';
import {TinglysningsData} from '../models/document.model';


@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
  fg: FormGroup;
  loading = false;
  error = false;
  buttonClicked = false;
  banks = [];

  constructor(private documentService: DocumentService) {
  }

  ngOnInit() {
    this.banks = this.documentService.getBanks();
    this.fg = new FormGroup({
      name: new FormControl(""),
      epost: new FormControl(""),
      mobil: new FormControl(""),
      firma: new FormControl(""),
      orgNr: new FormControl(""),
      adresse: new FormControl(""),
      postnr: new FormControl(""),
      poststed: new FormControl(""),
      bank: new FormControl({}),
      subIssuer: new FormControl({}),
      productionUrl: new FormControl(''),
      signatur1: new FormGroup({
        name: new FormControl(''),
        email: new FormControl(''),
        mobile: new FormControl('')
      }),
      signatur2: new FormGroup({
        name: new FormControl(''),
        email: new FormControl(''),
        mobile: new FormControl('')
      }),
      dpaName: new FormControl(true),
      dpaContact: new FormControl(true),
      dpaNumber: new FormControl(true),
      dpaNextOfKin: new FormControl(false),
      dpaChildren: new FormControl(false),
      dpaNin: new FormControl(true),
      dpaEngagement: new FormControl(false),
      dpaInsurance: new FormControl(false),
      dpaFinancial: new FormControl(false),
      dpaMedical: new FormControl(false),
      dpaGenetic: new FormControl(false),
      dpaBiometric: new FormControl(false),
      dpaSexual: new FormControl(false),
      dpaCriminal: new FormControl(false),
      dpaEthnic: new FormControl(false),
      dpaPolitical: new FormControl(false),
      dpaUnion: new FormControl(false),
      dpaComment: new FormControl(),
    });
  }

 

  onClick() {
    this.error = false;
    this.buttonClicked = true; 
    let tinglysningsData;
    if (!this.loading) {
      tinglysningsData = new TinglysningsData();
      tinglysningsData.name = this.fg.get('name').value;
      tinglysningsData.epost = this.fg.get('epost').value;
      tinglysningsData.mobil = this.fg.get('mobil').value;
      tinglysningsData.firma = this.fg.get('firma').value;
      tinglysningsData.orgNr = this.fg.get('orgNr').value;
      tinglysningsData.adresse = this.fg.get('adresse').value;
      tinglysningsData.postnr = this.fg.get('postnr').value;
      tinglysningsData.poststed = this.fg.get('poststed').value;
      tinglysningsData.bank = this.fg.get('bank').value;
      tinglysningsData.subIssuer = this.fg.get('subIssuer').value;
      tinglysningsData.productionUrl = this.fg.get('productionUrl').value;
      tinglysningsData.signature1 = this.fg.get('signatur1').value;
      tinglysningsData.signature2 = this.fg.get('signatur2').value;
      tinglysningsData.dpaName = this.fg.value.dpaName? 'X':' ';
      tinglysningsData.dpaContact = this.fg.value.dpaContact? 'X':' ';
      tinglysningsData.dpaNumber = this.fg.value.dpaNumber? 'X':' ';
      tinglysningsData.dpaNextOfKin = this.fg.value.dpaNextOfKin? 'X':' ';
      tinglysningsData.dpaChildren = this.fg.value.dpaChildren? 'X':' ';
      tinglysningsData.dpaNin = this.fg.value.dpaNin? 'X':' ';
      tinglysningsData.dpaEngagement = this.fg.value.dpaEngagement? 'X':' ';
      tinglysningsData.dpaInsurance = this.fg.value.dpaInsurance? 'X':' ';
      tinglysningsData.dpaFinancial = this.fg.value.dpaFinancial? 'X':' ';
      tinglysningsData.dpaMedical = this.fg.value.dpaMedical? 'X':' ';
      tinglysningsData.dpaGenetic = this.fg.value.dpaGenetic? 'X':' ';
      tinglysningsData.dpaBiometric = this.fg.value.dpaBiometric? 'X':' ';
      tinglysningsData.dpaSexual = this.fg.value.dpaSexual? 'X':' ';
      tinglysningsData.dpaCriminal = this.fg.value.dpaCriminal? 'X':' ';
      tinglysningsData.dpaEthnic = this.fg.value.dpaEthnic? 'X':' ';
      tinglysningsData.dpaPolitical = this.fg.value.dpaPolitical? 'X':' ';
      tinglysningsData.dpaUnion = this.fg.value.dpaUnion? 'X':' ';
      tinglysningsData.dpaComment = this.fg.get('dpaComment').value? this.fg.get('dpaComment').value : ' ';     
    }
    this.loading = true;
    this.documentService.postDocument(tinglysningsData).subscribe((res: any) => {
      this.loading = false;
      window.location.href = res.url;
    }, err => {
      this.error = true;
      this.loading = false;
      this.buttonClicked = false;
    });
  }
}
