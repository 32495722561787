import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { TinglysningsData } from '../models/document.model';
import { HttpClient, HttpClientModule, HttpHeaders } from '@angular/common/http';
import { FormControl } from '@angular/forms';

@Injectable(({ providedIn: 'root' }))
export class DocumentService {

  constructor(private http: HttpClient) {
  }

  postDocument(doc: TinglysningsData): Observable<any> {
    return this.http.post('/api/create', doc, { headers: { 'Content-Type': 'application/json' } });
  }
  postDpa(doc: TinglysningsData): Observable<any> {
    return this.http.post('/api/create-dpa', doc, { headers: { 'Content-Type': 'application/json' } });
  }
  getBanks(): any[] {
    return [
      {
          "name": "DnB",
          "entityId": 984851006,
          "subIssuers": null
      },
      {
          "name": "Nordea",
          "entityId": 924507500,
          "subIssuers": null
      },
      {
          "name": "Danske Bank",
          "entityId": 977074010,
          "subIssuers": null
      },
      {
          "name": "SpareBank1 Utvikling DA",
          "entityId": 986401598,
          "subIssuers": [
              {
                  "name": "SpareBank 1 BV",
                  "entityId": 944521836
              },
              {
                  "name": "SpareBank 1 Gudbrandsdal",
                  "entityId": 937888104
              },
              {
                  "name": "SpareBank 1 Hallingdal Valdres",
                  "entityId": 937889631
              },
              {
                  "name": "SpareBank 1 Lom og Skjåk",
                  "entityId": 937888015
              },
              {
                  "name": "SpareBank 1 Modum",
                  "entityId": 937889186
              },
              {
                  "name": "SpareBank 1 Nord-Norge",
                  "entityId": 952706365
              },
              {
                  "name": "SpareBank 1 Nordvest",
                  "entityId": 937899408
              },
              {
                  "name": "SpareBank 1 Østfold Akershus",
                  "entityId": 837884942
              },
              {
                  "name": "SpareBank 1 Østlandet",
                  "entityId": 920426530
              },
              {
                  "name": "SpareBank 1 Ringerike Hadeland",
                  "entityId": 937889275
              },
              {
                  "name": "SpareBank 1 SMN",
                  "entityId": 937901003
              },
              {
                  "name": "SpareBank 1 Søre Sunnmøre",
                  "entityId": 937899785
              },
              {
                  "name": "SpareBank 1 SR-Bank",
                  "entityId": 937895321
              },
              {
                  "name": "SpareBank 1 Telemark",
                  "entityId": 944521836
              },
              {
                  "name": "Sparebank1 Helgeland",
                  "entityId": 937904029
              }
          ]
      },
      {
          "name": "BID Bankene",
          "subIssuers": [
              {
                  "name": "Aasen Sparebank",
                  "entityId": 937903502
              },
              {
                  "name": "Askim & Spydeberg Sparebank",
                  "entityId": 937885199
              },
              {
                  "name": "Bank Norwegian",
                  "entityId": 991455671
              },
              {
                  "name": "BNBank",
                  "entityId": 914864445
              },
              {
                  "name": "Cultura Sparebank",
                  "entityId": 977041244
              },
              {
                  "name": "Drangedal Sparebank",
                  "entityId": 937891601
              },
              {
                  "name": "Easybank",
                  "entityId": 986144706
              },
              {
                  "name": "Etne Sparebank",
                  "entityId": 937897464
              },
              {
                  "name": "Fana Sparebank",
                  "entityId": 937896859
              },
              {
                  "name": "Flekkefjord Sparebank",
                  "entityId": 937894627
              },
              {
                  "name": "Gjensidige Bank",
                  "entityId": 990323429
              },
              {
                  "name": "Handelsbanken",
                  "entityId": 971171324
              },
              {
                  "name": "Haugesund Sparebank",
                  "entityId": 837895502
              },
              {
                  "name": "KLP Banken",
                  "entityId": 993821837
              },
              {
                  "name": "Landkreditt Bank",
                  "entityId": 980374181
              },
              {
                  "name": "Lillesands Sparebank",
                  "entityId": 937893655
              },
              {
                  "name": "Luster Sparebank",
                  "entityId": 937898614
              },
              {
                  "name": "Nidaros Sparebank",
                  "entityId": 937902719
              },
              {
                  "name": "Obos Bank",
                  "entityId": 911986884
              },
              {
                  "name": "Ørland Sparebank",
                  "entityId": 937901925
              },
              {
                  "name": "S banken ",
                  "entityId": 915287700
              },
              {
                  "name": "SEB",
                  "entityId": 971049944
              },
              {
                  "name": "Selbu Sparebank",
                  "entityId": 937901836
              },
              {
                  "name": "Skudenes & Aakra Sparebank",
                  "entityId": 937896670
              },
              {
                  "name": "Søgne og Greipstad Sparebank",
                  "entityId": 937895054
              },
              {
                  "name": "Sparebank 68° Nord",
                  "entityId": 937905378
              },
              {
                  "name": "Sparebanken DIN",
                  "entityId": 937891512
              },
              {
                  "name": "Sparebanken Møre",
                  "entityId": 937899319
              },
              {
                  "name": "Sparebanken Øst",
                  "entityId": 937888937
              },
              {
                  "name": "Sparebanken Sogn og Fjordane",
                  "entityId": 946670081
              },
              {
                  "name": "Sparebanken Sør",
                  "entityId": 937894538
              },
              {
                  "name": "Sparebanken Vest",
                  "entityId": 832554332
              },
              {
                  "name": "Spareskillingsbanken",
                  "entityId": 937894716
              },
              {
                  "name": "Stadsbygd Sparebank",
                  "entityId": 937902352
              },
              {
                  "name": "Storebrand Bank",
                  "entityId": 953299216
              },
              {
                  "name": "Swedbank",
                  "entityId": 880824872
              },
              {
                  "name": "Tolga-Os Sparebank",
                  "entityId": 816793432
              },
              {
                  "name": "Voss Sparebank",
                  "entityId": 937897286
              }
          ]
      },
      {
          "name": "Eika",
          "subIssuers": [
              {
                  "name": "Åfjord Sparebank",
                  "entityId": 937902441
              },
              {
                  "name": "Andebu Sparebank",
                  "entityId": 937890540
              },
              {
                  "name": "Arendal og Omegns Sparekasse",
                  "entityId": 937894082
              },
              {
                  "name": "Aurskog Sparebank",
                  "entityId": 937885644
              },
              {
                  "name": "Bank2",
                  "entityId": 988257133
              },
              {
                  "name": "Berg Sparebank",
                  "entityId": 937885288
              },
              {
                  "name": "Bien Sparebank AS",
                  "entityId": 991853995
              },
              {
                  "name": "Birkenes Sparebank",
                  "entityId": 937893833
              },
              {
                  "name": "Bjugn Sparebank",
                  "entityId": 937902085
              },
              {
                  "name": "Blaker Sparebank",
                  "entityId": 837886252
              },
              {
                  "name": "Eidsberg Sparebank",
                  "entityId": 937884494
              },
              {
                  "name": "Etnedal Sparebank",
                  "entityId": 937888570
              },
              {
                  "name": "Evje og Hornnes Sparebank",
                  "entityId": 937894171
              },
              {
                  "name": "Fornebubanken",
                  "entityId": 985750378
              },
              {
                  "name": "Gildeskål Sparebank",
                  "entityId": 937904673
              },
              {
                  "name": "Grong Sparebank",
                  "entityId": 937903146
              },
              {
                  "name": "Grue Sparebank",
                  "entityId": 937886705
              },
              {
                  "name": "Haltdalen Sparebank",
                  "entityId": 837902622
              },
              {
                  "name": "Harstad Sparebank",
                  "entityId": 937905378
              },
              {
                  "name": "Hegra Sparebank",
                  "entityId": 937903235
              },
              {
                  "name": "Hemne Sparebank",
                  "entityId": 937902174
              },
              {
                  "name": "Hjartdal og Gransherad Sparebank",
                  "entityId": 937893299
              },
              {
                  "name": "Hjelmeland Sparebank",
                  "entityId": 937896581
              },
              {
                  "name": "Høland og Setskog Sparebank",
                  "entityId": 937885822
              },
              {
                  "name": "Jæren Sparebank",
                  "entityId": 937895976
              },
              {
                  "name": "jbf bank og forsikring",
                  "entityId": 982719445
              },
              {
                  "name": "Jernbanepersonalets sparebank",
                  "entityId": 982719445
              },
              {
                  "name": "Kvinesdal Sparebank",
                  "entityId": 937894805
              },
              {
                  "name": "Larvikbanken",
                  "entityId": 937890729
              },
              {
                  "name": "LillestrømBanken",
                  "entityId": 937885911
              },
              {
                  "name": "Marker Sparebank",
                  "entityId": 937884672
              },
              {
                  "name": "Melhus Sparebank",
                  "entityId": 937901291
              },
              {
                  "name": "NORDirektebank",
                  "entityId": 937891245
              },
              {
                  "name": "Odal Sparebank",
                  "entityId": 937887043
              },
              {
                  "name": "Oppdalsbanken",
                  "entityId": 937901569
              },
              {
                  "name": "Orkla Sparebank",
                  "entityId": 947278770
              },
              {
                  "name": "Ørskog Sparebank",
                  "entityId": 837900212
              },
              {
                  "name": "Østre Agder Sparebank",
                  "entityId": 937894260
              },
              {
                  "name": "Rindal Sparebank",
                  "entityId": 937900953
              },
              {
                  "name": "Romsdalsbanken",
                  "entityId": 937900775
              },
              {
                  "name": "RørosBanken",
                  "entityId": 956548888
              },
              {
                  "name": "Sandnes Sparebank",
                  "entityId": 915691161
              },
              {
                  "name": "Skagerrak Sparebank",
                  "entityId": 937891245
              },
              {
                  "name": "Skue Sparebank",
                  "entityId": 837889812
              },
              {
                  "name": "Sogn Sparebank",
                  "entityId": 837897912
              },
              {
                  "name": "Soknedal Sparebank",
                  "entityId": 937902263
              },
              {
                  "name": "Sparebanken Narvik",
                  "entityId": 937903979
              },
              {
                  "name": "Strømmen Sparebank",
                  "entityId": 937886160
              },
              {
                  "name": "Sunndal Sparebank",
                  "entityId": 937899963
              },
              {
                  "name": "Surnadal Sparebank",
                  "entityId": 927041596
              },
              {
                  "name": "Tinn Sparebank",
                  "entityId": 937891423
              },
              {
                  "name": "Totens Sparebank",
                  "entityId": 937887787
              },
              {
                  "name": "Trøgstad Sparebank",
                  "entityId": 937885377
              },
              {
                  "name": "Tysnes Sparebank",
                  "entityId": 937897375
              },
              {
                  "name": "Valdres Sparebank",
                  "entityId": 937888759
              },
              {
                  "name": "Valle Sparebank",
                  "entityId": 937893922
              },
              {
                  "name": "Vekselbanken",
                  "entityId": 817244742
              }
          ]
      }
  ]
  }
}
